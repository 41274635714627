import { useTranslation } from 'react-i18next';
import ABDILogo from '../../../assets/images/abdibranco.png';
import SebraeLogo from '../../../assets/images/sebraebranco.png';
import SemagroLogo from '../../../assets/images/semagrobranco.png';
import { ImagesWrapper, SponsorsContainer } from './styles';

const Sponsors = () => {
  const { t } = useTranslation();

  return (
    <SponsorsContainer>
      {t('screens.sponsors.title')}
      <ImagesWrapper>
        <img src={ABDILogo} alt="ABDI Logo" />
        <img src={SebraeLogo} alt="ABDI Logo" />
        <img src={SemagroLogo} alt="ABDI Logo" />
      </ImagesWrapper>
    </SponsorsContainer>
  );
};

export default Sponsors;
