import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 27.5rem;
  height: 33.125rem;
  padding: 0 1rem;

  input:not(:first-of-type) {
    margin-top: 1rem;
  }

  span:last-of-type {
    margin-left: 0.5rem;
  }
`;

export const FormInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 20rem;
  padding: 0.75rem;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7em;

  @media (max-width: 1023px) {
    padding: 0.25rem;
  }
`;

export const FormButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 21rem;
  margin-top: 1rem;
  padding: 0.75em;
  border: none;
  border-radius: 30px;
  background-color: #0291da;

  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1em;
  text-transform: uppercase;

  cursor: pointer;
  :hover {
    background-color: #e6e6e6;
  }
`;

export const ErrorMessage = styled.span`
  display: flex;
  width: 100%;
  max-width: 21.5rem;

  color: #dc3232;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
`;

export const SuccessMessage = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  max-width: 21.5rem;

  color: #15b64d;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
`;
