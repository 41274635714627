import WhatsAppIcon from '../../assets/icons/WhatsAppIcon';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { WhatsAppIconContainer } from './styles';

const Home = () => {
  return (
    <>
      <Header />
      <Content />
      <Footer />
      <WhatsAppIconContainer href="https://web.whatsapp.com/send?phone=556733118542&text=" target="_blank">
        <WhatsAppIcon />
      </WhatsAppIconContainer>
    </>
  );
};

export default Home;
