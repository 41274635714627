import styled from 'styled-components';

export const WhatsAppIconContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 4.5rem;
  height: 4.5rem;
  background-color: #25d366;
  border-radius: 50%;
  z-index: 3;
  box-shadow: 0 0 1rem 0.25rem #000000;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #128c7e;
  }

  @media (max-width: 1023px) {
    width: 3.15rem;
    height: 3.15rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;
