import styled, { css } from 'styled-components';
import { ISyncAnimation } from '../../../interfaces/ISyncAnimation';

export const SoftwareScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 90rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  gap: 2rem;
`;

export const SoftwareScreenTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 71.25rem;
  gap: 1rem;

  text-align: center;
  color: #fff;
  font-family: 'Inter', Sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 1;
`;

export const SoftwareScreenContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div<ISyncAnimation>`
  display: flex;
  width: 100%;
  max-width: 40rem;
  margin-top: -3rem;

  img {
    width: 100%;
    max-width: 40rem;
  }

  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromLeft 1s ease-out;
    `}

  @media (max-width: 1023px) {
    align-self: center;
  }
`;

export const ContentRight = styled.div<ISyncAnimation>`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 29.625rem;
  height: 100%;
  margin-top: 2rem;

  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromRight 1s ease-out;
    `}

  @media (max-width: 1023px) {
    width: 20rem;
  }
`;

export const ContentRightText1 = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  color: #fff;
  font-family: 'Open Sans', Sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
`;

export const ContentRightText2 = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  font-family: 'Open Sans', Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.7em;

  li {
    list-style: disc;
  }
  @media (max-width: 400px) {
    width: 50%;
  }
`;
