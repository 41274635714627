import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #013a67;

  color: #ffffff;
  position: fixed;
  z-index: 2;
  top: 0;
  @media (max-width: 600px) {
    padding: 0 2rem;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6.25rem;

  max-width: 71.25rem;
  @media (max-width: 1023px) {
    justify-content: center;
    gap: 6rem;
    padding: 0 2rem;
  }
  @media (max-width: 850px) {
    gap: 2rem;
  }
`;

export const Logo = styled.img`
  display: flex;

  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;

  @media (max-width: 1023px) {
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
`;

export const LoginButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
  background-color: transparent;
  border: 3px solid #0274be;
  border-radius: 1.875rem;

  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Roboto', Sans-serif;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    transition: 0.3s;
    background-color: #0274be;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0.5rem 1rem;
  }
`;

export const RegisterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
  background-color: #0274be;
  border: 3px solid #0274be;
  border-radius: 1.875rem;

  white-space: nowrap;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Roboto', Sans-serif;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background-color: #808285;
    border: 3px solid #808285;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0.5rem 1rem;
  }
`;

export const TranslationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  background-color: #0274be;
  border: 3px solid #0274be;
  border-radius: 1.875rem;
  gap: 0.5rem;

  white-space: nowrap;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Roboto', Sans-serif;
  cursor: pointer;

  img {
    width: 1.5rem;
  }

  &:hover {
    transition: 0.3s;
    background-color: #808285;
    border: 3px solid #808285;
  }
`;

export const LanguageList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  background-color: #0274be;

  border-radius: 0.625rem;
`;

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  cursor: pointer;

  color: #ffffff;
  span {
    width: 100%;
    text-align: center;
  }

  img {
    width: 2rem;
  }

  &:hover {
    transition: 0.3s;
    color: #000000;
  }
`;

export const DropdownContainer = styled.div`
  cursor: pointer;

  :hover {
    svg {
      transition: 0.3s;
      fill: #0391da;
    }
  }
`;

export const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  background-color: #2a4d69;

  border-radius: 0.625rem;
`;
