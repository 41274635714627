import styled from 'styled-components';

export const LimitedScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 90rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  gap: 2rem;
`;

export const LimitedScreenTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 71.25rem;
  gap: 1rem;

  text-align: center;
  color: #fff;
  font-family: 'Inter', Sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 1;
`;

export const LimitedScreenContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  max-width: 80rem;
  gap: 1rem;

  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const LimitedCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 23.75rem;
  padding: 3rem 1rem;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;

  * {
    font-family: Sans-serif;
  }

  @media (max-width: 400px) {
    width: 21rem;
    height: 22.105rem;
  }
`;

export const LimitedIcon = styled.img`
  position: absolute;
  top: -45px;
`;

export const LimitedList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const LimitedListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  gap: 0.5rem;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
`;

export const LimitedItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  gap: 0.5rem;
  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
`;
