import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 17.25rem;
  height: 25.375rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 1.25rem;
`;

export const StepNumber = styled.div`
  color: #1b2d43;
  font-family: 'Open Sans', Sans-serif;
  font-size: 40px;
  font-weight: 700;
`;

export const StepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 100%;
  background-color: #22253e;

  svg {
    width: 3.125rem;
    height: 3.125rem;
    fill: #ffffff;
  }
`;

export const StepTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 2rem;
  font-family: 'Open Sans', Sans-serif;
  font-size: 24px;
  font-weight: 700;
`;

export const StepList = styled.ul`
  padding-left: 1rem;
  font-family: 'Open Sans', Sans-serif;
  font-size: 16px;
  font-weight: 300;
`;

export const StepListItem = styled.li`
  font-family: 'Open Sans', Sans-serif;
  line-height: 2em;
  list-style: disc;
  font-size: 12px;
  font-weight: 600;
`;
