export default {
  translation: {
    buttons: {
      login: 'Login',
      join: 'Quero me cadastrar',
      software: 'Software',
      how: 'Como funciona',
      about: 'Sobre o projeto',
      video: 'Vídeo',
      video_sec: 'Veja o vídeo completo!',
      send: 'Enviar',
    },
    placeholders: {
      name: 'Nome Completo',
      position: 'Cargo',
      email: 'E-mail',
      phone: 'Telefone',
      company: 'Empresa',
      registry: 'CNPJ',
    },
    screens: {
      first: {
        content1: 'GESTÃO DE ATIVOS E MANUTENÇÃO COM O',
        content2: 'APOIO DE QUEM ENTENDE!',
        content3: 'Evite falhas em suas máquinas com um software SIMPLES, INTUITIVO E PARTICIPATIVO',
        content4:
          'Nosso time de especialistas do SENAI acompanhará todo o processo de transformação. Solicite uma avaliação gratuita!',
        content5: 'São poucas vagas.',
        content6: 'Cadastre-se',
        content7: 'para confirmar o interesse em participar do programa!',
        content8: '* Avaliaremos o perfil da empresa e a aderência às regras da ABDI.',
        content9: 'Realização',
      },
      sponsors: {
        title: 'APOIO',
      },
      video: {
        content1: 'Conheça o PCM Digital',
        content2: 'O PCM Digital apoia empresas na implantação de um software completo e intuitivo para melhorar o',
        content3: 'planejamento e controle das ações de manutenção',
        content4: 'com o',
        content5: 'acompanhamento imersivo',
        content6: 'da equipe do',
        content7: 'SENAI',
        content8: 'Veja o vídeo completo!',
        content9: 'PCM DIGITAL - APRESENTAÇÃO',
      },
      second: {
        content1: 'Pare',
        content2: 'de perder tempo e dinheiro com paradas de',
        content3: 'manutenção inesperadas!',
        content4:
          'Se a sua empresa tem dificuldade em controlar a manutenção das máquinas, quer melhorar o desempenho da produção ou economizar com insumos e materiais, o PCM digital é a solução ideal.',
        content5: 'O PCM DIGITAL É PARA EMPRESAS QUE QUEREM:',
        content6: 'Evitar falhas em máquinas e paradas de produção',
        content7: 'Otimizar custos de manutenção',
        content8: 'Reduzir estoques de peças e materiais de manutenção',
        content9: 'Utilizar melhor a mão-de-obra e os contratos de prestação de serviço',
        content10: 'Tomar melhores decisões com dados e indicadores',
      },
      third: {
        content1: 'Como funciona',
        content2: 'Com o acompanhamento imersivo do SENAI, o projeto terá as seguintes etapas:',
        cards: {
          diagnostics: {
            title: 'Diagnóstico',
            content1: 'Mapeamento dos ativos',
            content2: 'Compreensão dos fluxos de informação',
            content3: 'Avaliação de maturidade da empresa',
            content4: 'Disponibilização do software do SENAI',
            content5: 'Capacitação dos usuários',
          },
          registers: {
            title: 'Cadastros',
            content1: 'Cadastro dos ativos',
            content2: 'Emissão das Tags com QRcodes',
            content3: 'Cadastro dos colaboradores, fornecedores e contratos',
          },
          services: {
            title: 'Serviços',
            content1: 'Registro das manutenções',
            content2: 'Solicitação de serviço (SS)',
            content3: 'Ordens de serviço (OS)',
          },
          planning: {
            title: 'Planejamento',
            content1: 'Elaboração de planos de manutenção',
            content2: 'Procedimentos padronizados',
            content3: 'Planejamento e controle das ações',
            content4: 'Inspeções por checklist',
          },
          indicators: {
            title: 'Indicadores',
            content1: 'Informações para tomada de decisão',
            content2: 'Gráficos e relatórios de fácil compreensão',
            content3: 'Gestão à vista',
            content4: 'Controle dos custos de manutenção',
            content5: 'Estatísticas de Ordens de Serviço',
          },
        },
      },
      software: {
        content1: 'Software',
        content2: 'Software completo, simples e intuitivo',
        content3: 'Desenvolvido pelo SENAI',
        content4: 'Interface amigável',
        content5: 'Sem instalação (100% nuvem)',
        content6: 'Sem limite de usuários',
        content7: 'Atualizações automáticas',
        content8: 'Ideal para smartphones e tablets',
        content9: 'Melhores práticas em segurança de dados',
        content10: 'Suporte especializado do SENAI',
        card1: {
          title: 'Almoxarifado de materiais',
          content1: 'Gestão de materiais e peças',
          content2: 'Alertas de estoque mínimo',
          content3: 'Requisição e aprovação de compras',
          content4: 'Controle de entrada e saída',
        },
        card2: {
          title: 'Indicadores de Perfomance',
          content1: 'Custo e impactos de produção',
          content2: 'Tempo entre Falhas e Tempo de Reparo',
          content3: 'Disponibilidade e Confiabilidade',
          content4: 'Rendimento (mão de obra e condutores)',
          content5: 'Custo total operacional de frotas',
        },
        card3: {
          title: 'Mobilidade',
          content1: 'Cadastro simplificado dos ativos pelo celular',
          content2: 'Leitura de etiquetas (TAG) com QR-Code',
          content3: 'Acesso a informações e anexos de qualquer lugar',
        },
        card4: {
          title: 'Gestão de serviços',
          content1: 'Solicitações de Serviços (SS)',
          content2: 'Ordens de Serviços (OS)',
          content3: 'Gestão à vista',
        },
        card5: {
          title: 'Painel de Gestão',
          content1: 'Agenda de serviços',
          content2: 'Estatística de ordens de serviço',
          content3: 'Status de ativos',
          content4: 'Principais indicadores de manutenção',
        },
        card6: {
          title: 'Cadastro de Ativos',
          content1: 'Fotos e anexos',
          content2: 'Edificações e setores',
          content3: 'Linhas de produção',
          content4: 'Máquinas e equipamentos',
          content5: 'Informações auditáveis',
        },
        card7: {
          title: 'Planeamento de Manutenção',
          content1: 'Planos preventivos automatizados',
          content2: 'Checklist e inspeções preditivas',
          content3: 'Rotas de Lubrificação',
        },
        card8: {
          title: 'Gestão de Frotas',
          content1: 'Gestão de materiais e peças',
          content2: 'Alertas de estoque mínimo',
          content3: 'Requisição e aprovação de compras',
          content4: 'Controle de entrada e saída',
        },
      },
      limited: {
        title: 'Vagas Limitadas',
        card1: {
          title: 'Serviços',
          content1: 'Cadastro dos ativos dos clientes',
          content2: 'Histórico das manutenções realizadas nos clientes',
          content3: 'Controle dos materiais que cada cliente utiliza',
          content4: 'Controle da periodicidade dos serviços nos clientes',
          content5: 'Gestão dos contratos de prestação de serviços',
          content6: 'Possibilidade de ações preventivas no cliente',
          content7: 'Agendamento de veículos',
          content8: 'Indicadores de desempenho',
        },
        card2: {
          title: 'Agronegócio',
          content1: 'Cadastro de máquinas e implementos agrícolas',
          content2: 'Cadastro de edificações',
          content3: 'Controle de planos de manutenção',
          content4: 'Gestão de abastecimentos e pneus',
          content5: 'Gestão e avaliação de contratos terceiros',
          content6: 'Controle de materiais com mínimos e máximos',
          content7: 'Mobilidade',
          content8: 'Indicadores de desempenho',
        },
        card3: {
          title: 'Indústria',
          content1: 'Cadastro de linhas, máquinas, equipamentos e criticidade de cada um deles',
          content2: 'Controle de planos de manutenção',
          content3: 'Otimização de serviços e mão de obra',
          content4: 'Gestão e avaliação de contratos terceiros',
          content5: 'Controle dos materiais com estoques mínimos e máximos',
          content6: 'Gestão de segurança das máquinas e equipamentos (NR12)',
          content7: 'Controle de frotas Indicadores de desempenho',
        },
        alt1: 'Serviços',
        alt2: 'Agronegócio',
        alt3: 'Indústria',
      },
      join: {
        content1: 'Quero participar',
      },
    },
    footer: {
      contact: 'Rua Pimenta Bueno, 370 – Amambai, Campo Grande – MS • Telefone: (67) 3311-8511 • Campo Grande / MS',
      rights: 'Todos os direitos reservados ® 2022.',
    },
  },
};
