import { useTranslation } from 'react-i18next';
import Limited2 from '../../../assets/images/LimitedAgroIcon.png';
import Limited1 from '../../../assets/images/LimitedIndIcon.png';
import Divider from '../Divider';
import {
  LimitedCard,
  LimitedIcon,
  LimitedItem,
  LimitedList,
  LimitedListTitle,
  LimitedScreenContainer,
  LimitedScreenContent,
  LimitedScreenTitle,
} from './styles';

const LimitedScreen = () => {
  const { t } = useTranslation();

  return (
    <LimitedScreenContainer>
      <LimitedScreenTitle>{t('screens.limited.title')}</LimitedScreenTitle>
      <Divider />
      <LimitedScreenContent>
        <LimitedCard>
          <LimitedIcon src={Limited1} alt={t('screens.limited.alt1')} />
          <LimitedList>
            <LimitedListTitle>{t('screens.limited.card1.title')}</LimitedListTitle>
            <LimitedItem>{t('screens.limited.card1.content1')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card1.content2')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card1.content3')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card1.content4')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card1.content5')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card1.content6')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card1.content7')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card1.content8')}</LimitedItem>
          </LimitedList>
        </LimitedCard>
        <LimitedCard>
          <LimitedIcon src={Limited2} alt={t('screens.limited.alt2')} />
          <LimitedList>
            <LimitedListTitle>{t('screens.limited.card2.title')}</LimitedListTitle>
            <LimitedItem>{t('screens.limited.card2.content1')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card2.content2')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card2.content3')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card2.content4')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card2.content5')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card2.content6')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card2.content7')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card2.content8')}</LimitedItem>
          </LimitedList>
        </LimitedCard>
        <LimitedCard>
          <LimitedIcon src={Limited1} alt={t('screens.limited.alt3')} />
          <LimitedList>
            <LimitedListTitle>{t('screens.limited.card3.title')}</LimitedListTitle>
            <LimitedItem>{t('screens.limited.card3.content1')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card3.content2')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card3.content3')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card3.content4')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card3.content5')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card3.content6')}</LimitedItem>
            <LimitedItem>{t('screens.limited.card3.content7')}</LimitedItem>
          </LimitedList>
        </LimitedCard>
      </LimitedScreenContent>
    </LimitedScreenContainer>
  );
};

export default LimitedScreen;
