import { Step } from '../../../../interfaces/IStep';
import Card from './Card';
import { StepsContainer } from './styles';

const Steps = ({ stepsInfo }: { stepsInfo: Array<Step> }) => {
  return (
    <StepsContainer>
      {stepsInfo &&
        stepsInfo.map((step: Step) => {
          return <Card key={step?.number} step={step} />;
        })}
    </StepsContainer>
  );
};

export default Steps;
