import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 67.625rem;
  margin-top: -7rem;

  @media (max-width: 1023px) {
    margin-top: -5rem;
    margin-left: 1rem;
    width: 44.75rem;
  }
  @media (max-width: 850px) {
    width: 21.875rem;
  }
  @media (max-width: 400px) {
    width: 18rem;
  }

  .ant-carousel {
    width: 100%;
    max-width: 67.625rem;
    margin: 0 auto;
    &:hover,
    &:focus {
      cursor: grab;
    }

    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slick-prev,
    .slick-prev:focus {
      width: 25px;
      height: 25px;
      font-size: 1.5em;
      z-index: 1;
      fill: #fff;
    }

    .slick-next {
      transform: rotate(180deg);
    }

    .slick-next,
    .slick-next:focus {
      font-size: 1.5em;
      z-index: 1;
      fill: #fff;
    }

    .slick-prev:hover,
    .slick-next:hover {
      transition: 0.3s;
      fill: #0391da;
    }

    .slick-prev::before,
    .slick-next::before {
      content: none;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

export const CarouselCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 21rem;
  height: 22rem;
  padding-top: 1rem;
  background-color: #ffffff;
  border-radius: 10px;

  * {
    font-family: Sans-serif;
  }

  @media (max-width: 400px) {
    width: 18rem;
    height: 19rem;
  }
`;

export const CarouselCardIcon = styled.img`
  height: 12.625rem;
`;

export const CarouselCardList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const CarouselListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0.5rem;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
`;

export const CarouselCardItem = styled.li`
  width: 100%;
  text-align: start;
  color: #000;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.25;
`;
