import styled from 'styled-components';
import backgroundImage from '../../assets/images/background.jpg';

export const ContentContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 6.25rem;
  overflow-x: hidden;
`;

export const FirstScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const SecondaryScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;
  background-color: #1b2d43;
`;

export const VideoScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
`;

export const SectionDivider = styled.div`
  background-color: #1b2d43;

  width: 100%;
  max-width: 64rem;
  border-bottom: 5px solid #878785;
  border-radius: 0.625rem;
`;
