import { useTranslation } from 'react-i18next';
import M1 from '../../../assets/images/M1.png';
import M2 from '../../../assets/images/M2.png';
import M3 from '../../../assets/images/M3.png';
import M4 from '../../../assets/images/M4.png';
import SenaiLogo from '../../../assets/images/senai-logo.png';
import useOnScreen from '../../../hooks/useOnScreen';
import {
  ContentContainer,
  ContentLeft,
  ContentLeftText1,
  ContentLeftText2,
  ContentRight,
  FirstScreenContainer,
  ImageFader,
  RegisterButton,
  SenaiLogoContainer,
} from './styles';

const FirstScreen = () => {
  const [ref, isVisible] = useOnScreen({
    rootMargin: '0px',
  });
  const { t } = useTranslation();

  const scrollToComponent = (componentId: string) => {
    const element = document.getElementById(componentId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  };

  return (
    <FirstScreenContainer>
      <ContentContainer>
        <ContentLeft ref={ref} isVisible={isVisible}>
          <ContentLeftText1>
            {t('screens.first.content1')} <strong>{t('screens.first.content2')}</strong>
          </ContentLeftText1>
          <ContentLeftText2>{t('screens.first.content3')}</ContentLeftText2>
          <ContentLeftText2>{t('screens.first.content4')}</ContentLeftText2>
          <RegisterButton onClick={() => scrollToComponent('join-screen')}>{t('buttons.join')}</RegisterButton>
          <ContentLeftText2>
            {t('screens.first.content5')} <strong>{t('screens.first.content6')}</strong> {t('screens.first.content7')}
          </ContentLeftText2>
          <ContentLeftText2>{t('screens.first.content8')}</ContentLeftText2>
        </ContentLeft>
        <ContentRight ref={ref} isVisible={isVisible}>
          <ImageFader>
            <img src={M1} alt="fading image 1" />
            <img src={M2} alt="fading image 2" />
            <img src={M3} alt="fading image 3" />
            <img src={M4} alt="fading image 4" />
          </ImageFader>
        </ContentRight>
      </ContentContainer>
      <ContentLeftText2>{t('screens.first.content9')}</ContentLeftText2>

      <SenaiLogoContainer>
        <img src={SenaiLogo} alt="Senai Logo" />
      </SenaiLogoContainer>
    </FirstScreenContainer>
  );
};

export default FirstScreen;
