/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { NavbarContainer, ScrollToButton } from './styles';

const Navbar = ({ closeDropDown }: { closeDropDown: any }) => {
  const { t } = useTranslation();

  const scrollToComponent = (componentId: string) => {
    const element = document.getElementById(componentId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  };
  return (
    <NavbarContainer>
      <ScrollToButton
        onClick={() => {
          scrollToComponent('video-screen');
          closeDropDown();
        }}
      >
        {t('buttons.video')}
      </ScrollToButton>
      <ScrollToButton
        onClick={() => {
          scrollToComponent('about-screen');
          closeDropDown();
        }}
      >
        {t('buttons.about')}
      </ScrollToButton>
      <ScrollToButton
        onClick={() => {
          scrollToComponent('how-works-screen');
          closeDropDown();
        }}
      >
        {t('buttons.how')}
      </ScrollToButton>
      <ScrollToButton
        onClick={() => {
          scrollToComponent('software-screen');
          closeDropDown();
        }}
      >
        {t('buttons.software')}
      </ScrollToButton>
    </NavbarContainer>
  );
};

export default Navbar;
