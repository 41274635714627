import styled from 'styled-components';

export const NavbarContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 2rem;
  gap: 1.5rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }
`;

export const ScrollToButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 1.125rem;
  background-color: transparent;
  border: none;
  font-family: 'Roboto', Sans-serif;
  font-size: 15px;
  color: #fff;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    color: #0391da;
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`;
