import { useTranslation } from 'react-i18next';
import useOnScreen from '../../../hooks/useOnScreen';
import {
  VideoButton,
  VideoDescriptionContainer,
  VideoDescriptionText,
  VideoDescriptionTitle,
  VideoScreenContainer,
  VideoWrapper,
} from './styles';

const VideoScreen = () => {
  const [ref, isVisible] = useOnScreen({
    rootMargin: '0px',
  });
  const { t } = useTranslation();

  return (
    <VideoScreenContainer>
      <VideoDescriptionContainer ref={ref} isVisible={isVisible}>
        <VideoDescriptionTitle>{t('screens.video.content1')}</VideoDescriptionTitle>
        <VideoDescriptionText>
          {t('screens.video.content2')}
          <strong> {t('screens.video.content3')} </strong> {t('screens.video.content4')}
          <strong> {t('screens.video.content5')} </strong>
          {t('screens.video.content6')} <strong>{t('screens.video.content7')}</strong>
        </VideoDescriptionText>
        <VideoButton href="https://www.youtube.com/watch?v=IRR2ElA7Txo" target="_blank">
          {t('screens.video.content8')}
        </VideoButton>
      </VideoDescriptionContainer>
      <VideoWrapper ref={ref} isVisible={isVisible}>
        <div style={{ paddingBottom: '56.25%', position: 'relative', display: 'block', width: '100%' }}>
          <iframe
            className="elementor-video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            title={t('screens.video.content9')}
            frameBorder="0"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/IRR2ElA7Txo?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fpcmdigital.com.br&amp;widgetid=1"
            id="widget2"
            style={{ position: 'absolute', top: 0, left: 0 }}
          ></iframe>
        </div>
      </VideoWrapper>
    </VideoScreenContainer>
  );
};

export default VideoScreen;
