import { Carousel } from 'antd';
import { useTranslation } from 'react-i18next';
import ArrowLeft from '../../../../assets/icons/ArrowLeft';
import Module1 from '../../../../assets/images/ModuleIcon1.png';
import Module2 from '../../../../assets/images/ModuleIcon2.png';
import Module3 from '../../../../assets/images/ModuleIcon3.png';
import Module4 from '../../../../assets/images/ModuleIcon4.png';
import Module5 from '../../../../assets/images/ModuleIcon5.png';
import Module6 from '../../../../assets/images/ModuleIcon6.png';
import Module7 from '../../../../assets/images/ModuleIcon7.png';
import {
  CarouselCard,
  CarouselCardIcon,
  CarouselCardItem,
  CarouselCardList,
  CarouselContainer,
  CarouselListTitle,
} from './styles';

export default function App() {
  const { t } = useTranslation();

  return (
    <CarouselContainer>
      <Carousel
        autoplay={true}
        autoplaySpeed={2500}
        dots={false}
        infinite={true}
        slidesToShow={3}
        slidesToScroll={1}
        arrows={true}
        prevArrow={
          <div>
            <ArrowLeft />
          </div>
        }
        nextArrow={
          <div className="rotated-arrow">
            <ArrowLeft />
          </div>
        }
        draggable
        responsive={[
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ]}
      >
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module1} alt="Module" />
            <CarouselListTitle>{t('screens.software.card1.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card1.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card1.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card1.content3')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card1.content4')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module2} alt="Module" />
            <CarouselListTitle>{t('screens.software.card2.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card2.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card2.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card2.content3')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card2.content4')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card2.content5')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module3} alt="Module" />
            <CarouselListTitle>{t('screens.software.card3.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card3.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card3.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card3.content3')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module4} alt="Module" />
            <CarouselListTitle>{t('screens.software.card4.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card4.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card4.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card4.content3')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module5} alt="Module" />
            <CarouselListTitle>{t('screens.software.card5.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card5.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card5.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card5.content3')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card5.content4')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module6} alt="Module" />
            <CarouselListTitle>{t('screens.software.card6.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card6.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card6.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card6.content3')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card6.content4')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card6.content4')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module7} alt="Module" />
            <CarouselListTitle>{t('screens.software.card7.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card7.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card7.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card7.content3')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
        <div>
          <CarouselCard>
            <CarouselCardIcon src={Module4} alt="Module" />
            <CarouselListTitle>{t('screens.software.card8.title')}</CarouselListTitle>
            <CarouselCardList>
              <CarouselCardItem>{t('screens.software.card8.content1')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card8.content2')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card8.content3')}</CarouselCardItem>
              <CarouselCardItem>{t('screens.software.card8.content4')}</CarouselCardItem>
            </CarouselCardList>
          </CarouselCard>
        </div>
      </Carousel>
    </CarouselContainer>
  );
}
