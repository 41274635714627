import { useTranslation } from 'react-i18next';
import SSMI2 from '../../../assets/images/SSMI2.png';
import useOnScreen from '../../../hooks/useOnScreen';

import Divider from '../Divider';
import Carousel from './Carousel';
import {
  ContentLeft,
  ContentRight,
  ContentRightText1,
  ContentRightText2,
  SoftwareScreenContainer,
  SoftwareScreenContent,
  SoftwareScreenTitle,
} from './styles';

const SoftwareScreen = () => {
  const [ref, isVisible] = useOnScreen({
    rootMargin: '0px',
  });
  const { t } = useTranslation();

  return (
    <SoftwareScreenContainer>
      <SoftwareScreenTitle>{t('screens.software.content1')}</SoftwareScreenTitle>
      <Divider />
      <SoftwareScreenContent>
        <ContentLeft ref={ref} isVisible={isVisible}>
          <img src={SSMI2} alt="SSMI image 1" />
        </ContentLeft>
        <ContentRight ref={ref} isVisible={isVisible}>
          <ContentRightText1>{t('screens.software.content2')}</ContentRightText1>
          <ContentRightText2>
            <li>{t('screens.software.content3')}</li>
            <li>{t('screens.software.content4')}</li>
            <li>{t('screens.software.content5')}</li>
            <li>{t('screens.software.content6')}</li>
            <li>{t('screens.software.content7')}</li>
            <li>{t('screens.software.content8')}</li>
            <li>{t('screens.software.content9')}</li>
            <li>{t('screens.software.content10')}</li>
          </ContentRightText2>
        </ContentRight>
      </SoftwareScreenContent>
      <Carousel />
    </SoftwareScreenContainer>
  );
};

export default SoftwareScreen;
