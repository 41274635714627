/* eslint-disable no-debugger */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormData } from '../../../../interfaces/IFormData';
import { IFormErrors } from '../../../../interfaces/IFormErrors';
import { ErrorMessage, FormButton, FormContainer, FormInput, SuccessMessage } from './styles';

const Form: React.FC = () => {
  const [formData, setFormData] = useState<IFormData>({
    fullName: '',
    company: '',
    email: '',
    cnpj: '',
    phone: '',
    position: '',
  });

  const [formErrors, setFormErrors] = useState<IFormErrors>({
    fullName: '',
    company: '',
    email: '',
    phone: '',
    position: '',
    general: '',
  });
  const [successMessage, setSuccessMessage] = useState(false);
  const { t } = useTranslation();

  const validateField = useCallback(
    (name: string, value: string): string => {
      if (['fullName', 'company', 'position'].includes(name) && value.trim() === '') {
        return 'Este campo é obrigatório.';
      }
      if ((name === 'email' || name === 'phone') && !formData.email.trim() && !formData.phone.trim()) {
        return 'É necessário fornecer pelo menos um contato.';
      }
      return '';
    },
    [formData.email, formData.phone]
  );

  const maskPhone = (value: string): string => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      let { value } = e.target;

      if (name === 'phone') {
        value = maskPhone(value);
      }
      const fieldError = validateField(name, value);

      setFormData({
        ...formData,
        [name]: value,
      });

      setFormErrors({
        ...formErrors,
        [name]: fieldError,
        general: '',
      });
    },
    [formData, formErrors, validateField]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setSuccessMessage(false);
      const newErrors: IFormErrors = {
        fullName: validateField('fullName', formData.fullName),
        company: validateField('company', formData.company),
        email: validateField('email', formData.email),
        phone: validateField('phone', formData.phone),
        position: validateField('position', formData.position),
        general: '',
      };

      const hasError = Object.values(newErrors).some((error) => error !== '');

      if (hasError) {
        setFormErrors({
          ...newErrors,
          general: 'Um ou mais campos possuem um erro. Verifique e tente novamente.',
        });
      } else {
        const payload = {
          subject: 'Portal PCM-Digital: Contato de Interesse',
          tipoConteudo: 'HTML',
          body: `Nome: ${formData?.fullName}<br/>Cargo: ${formData?.position}<br/>E-mail: ${formData?.email}<br/>Telefone: ${formData?.phone}<br/>Empresa: ${formData?.company}<br/>CNPJ: ${formData?.cnpj}`,
        };
        try {
          await fetch('https://api.ssmi.ms.senai.br/email-app/mail/send/to/me', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
          setSuccessMessage(true);
        } catch (error) {
          console.error('Erro ao enviar formulário:', error);
        }

        setFormData({
          fullName: '',
          company: '',
          email: '',
          cnpj: '',
          phone: '',
          position: '',
        });
        setFormErrors({
          fullName: '',
          company: '',
          email: '',
          phone: '',
          position: '',
          general: '',
        });
        console.debug('Form Data Submitted:', formData);
      }
    },
    [formData, validateField]
  );

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormInput
        type="text"
        name="fullName"
        placeholder={t('placeholders.name')}
        value={formData.fullName}
        onChange={handleChange}
      />
      {formErrors.fullName && <ErrorMessage>{formErrors.fullName}</ErrorMessage>}

      <FormInput
        type="text"
        name="position"
        placeholder={t('placeholders.position')}
        value={formData.position}
        onChange={handleChange}
      />
      {formErrors.position && <ErrorMessage>{formErrors.position}</ErrorMessage>}

      <FormInput
        type="email"
        name="email"
        placeholder={t('placeholders.email')}
        value={formData.email}
        onChange={handleChange}
      />
      {formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage>}

      <FormInput
        type="tel"
        name="phone"
        placeholder={t('placeholders.phone')}
        value={formData.phone}
        onChange={handleChange}
      />
      {formErrors.phone && <ErrorMessage>{formErrors.phone}</ErrorMessage>}

      <FormInput
        type="text"
        name="company"
        placeholder={t('placeholders.company')}
        value={formData.company}
        onChange={handleChange}
      />
      {formErrors.company && <ErrorMessage>{formErrors.company}</ErrorMessage>}

      <FormInput
        type="text"
        name="cnpj"
        placeholder={t('placeholders.registry')}
        value={formData.cnpj}
        onChange={handleChange}
      />

      <FormButton type="submit">{t('buttons.send')}</FormButton>
      {formErrors.general && <ErrorMessage>{formErrors.general}</ErrorMessage>}
      {successMessage && <SuccessMessage>Formulário enviado com sucesso!</SuccessMessage>}
    </FormContainer>
  );
};

export default Form;
