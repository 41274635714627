import styled from 'styled-components';
import backgroundImage from '../../assets/images/bgLogin.jpg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
`;
