import { useTranslation } from 'react-i18next';
import DiagIcon from '../../../assets/icons/DiagIcon';
import IndicatorsIcon from '../../../assets/icons/IndicatorsIcon';
import PlanningIcon from '../../../assets/icons/PlanningIcon';
import RegisterIcon from '../../../assets/icons/RegisterIcon';
import ServicesIcon from '../../../assets/icons/ServicesIcon';
import Divider from '../Divider';
import Steps from './Steps';
import { ThirdScreenContainer, ThirdScreenTitle } from './styles';

const ThirdScreen = () => {
  const { t } = useTranslation();

  const stepsInfoMock = [
    {
      number: '1°',
      icon: <DiagIcon />,
      title: t('screens.third.cards.diagnostics.title'),
      list: [
        t('screens.third.cards.diagnostics.content1'),
        t('screens.third.cards.diagnostics.content2'),
        t('screens.third.cards.diagnostics.content3'),
        t('screens.third.cards.diagnostics.content4'),
        t('screens.third.cards.diagnostics.content5'),
      ],
    },
    {
      number: '2°',
      icon: <RegisterIcon />,
      title: t('screens.third.cards.registers.title'),
      list: [
        t('screens.third.cards.registers.content1'),
        t('screens.third.cards.registers.content2'),
        t('screens.third.cards.registers.content3'),
      ],
    },
    {
      number: '3°',
      icon: <ServicesIcon />,
      title: t('screens.third.cards.services.title'),
      list: [
        t('screens.third.cards.services.content1'),
        t('screens.third.cards.services.content2'),
        t('screens.third.cards.services.content3'),
      ],
    },
    {
      number: '4°',
      icon: <PlanningIcon />,
      title: t('screens.third.cards.planning.title'),
      list: [
        t('screens.third.cards.planning.content1'),
        t('screens.third.cards.planning.content2'),
        t('screens.third.cards.planning.content3'),
        t('screens.third.cards.planning.content4'),
      ],
    },
    {
      number: '5°',
      icon: <IndicatorsIcon />,
      title: t('screens.third.cards.indicators.title'),
      list: [
        t('screens.third.cards.indicators.content1'),
        t('screens.third.cards.indicators.content2'),
        t('screens.third.cards.indicators.content3'),
        t('screens.third.cards.indicators.content4'),
        t('screens.third.cards.indicators.content5'),
      ],
    },
  ];

  return (
    <ThirdScreenContainer>
      <ThirdScreenTitle>
        {t('screens.third.content1')}
        <span>{t('screens.third.content2')}</span>
      </ThirdScreenTitle>
      <Divider />
      <Steps stepsInfo={stepsInfoMock} />
    </ThirdScreenContainer>
  );
};

export default ThirdScreen;
