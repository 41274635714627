import { GlobalOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PCMDLogo from '../../assets/images/logo4x.png';
import i18n from '../../translation';
import locales from '../../translation/locales';
import Navbar from '../Navbar';
import {
  ButtonsContainer,
  DropdownContainer,
  DropdownList,
  HeaderContainer,
  HeaderContent,
  LanguageContainer,
  LanguageList,
  LoginButton,
  Logo,
  RegisterButton,
  TranslationButton,
} from './styles';

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.outerWidth < 1023);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [languageFlag, setLanguageFlag] = useState('BR');
  const { t } = useTranslation();
  const localStorageLanguage = localStorage.getItem('@pcmd/language');

  const scrollToComponent = (componentId: string) => {
    const element = document.getElementById(componentId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  };

  function handleChangeLanguage(language: string) {
    i18n.changeLanguage(language);
    localStorage.setItem('@pcmd/language', language);
  }

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.outerWidth < 1023);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const userLanguage = window.navigator.language;

    const translationsOptions = Object.keys(locales);
    const translation = translationsOptions.find((option) =>
      option.toLowerCase().includes(userLanguage.split('-')[0].toLowerCase())
    );

    if (localStorageLanguage) {
      setLanguageFlag(localStorageLanguage.split('-')[1]);
    } else if (translation) {
      setLanguageFlag(translation.split('-')[1]);
    } else {
      setLanguageFlag('BR');
    }
  }, [localStorageLanguage]);

  return (
    <HeaderContainer>
      <HeaderContent>
        <Logo src={PCMDLogo} alt="PCMD Logo" onClick={() => scrollTo({ top: 20, behavior: 'smooth' })} />
        {isMobile ? (
          <Dropdown
            open={dropdownOpen}
            onVisibleChange={(open) => setDropdownOpen(open)}
            overlay={
              <DropdownList>
                <Navbar closeDropDown={closeDropdown} />
                <ButtonsContainer>
                  <LoginButton onClick={closeDropdown} href="https://ssmi.ms.senai.br/login.jsp" target="_blank">
                    {t('buttons.login')}
                  </LoginButton>
                  <RegisterButton
                    type="button"
                    onClick={() => {
                      closeDropdown();
                      scrollToComponent('join-screen');
                    }}
                  >
                    {t('buttons.join')}
                  </RegisterButton>
                  <Dropdown
                    overlay={
                      <LanguageList>
                        <LanguageContainer onClick={() => handleChangeLanguage('en-US')}>
                          <span>English</span>
                          <img
                            alt="United States"
                            src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                          />
                        </LanguageContainer>
                        <LanguageContainer onClick={() => handleChangeLanguage('es-ES')}>
                          <span>Español</span>

                          <img
                            alt="United States"
                            src="http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg"
                          />
                        </LanguageContainer>
                        <LanguageContainer onClick={() => handleChangeLanguage('pt-BR')}>
                          <span>Português</span>
                          <img
                            alt="United States"
                            src="http://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg"
                          />
                        </LanguageContainer>
                      </LanguageList>
                    }
                  >
                    <TranslationButton>
                      <GlobalOutlined />
                      <img
                        alt="Language Flag"
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${languageFlag}.svg`}
                      />
                    </TranslationButton>
                  </Dropdown>
                </ButtonsContainer>
              </DropdownList>
            }
          >
            <DropdownContainer>
              <MenuOutlined />
            </DropdownContainer>
          </Dropdown>
        ) : (
          <HeaderContent>
            <Navbar closeDropDown={() => {}} />
            <ButtonsContainer>
              <RegisterButton type="button" onClick={() => scrollToComponent('join-screen')}>
                {t('buttons.join')}
              </RegisterButton>
              <LoginButton href="https://ssmi.ms.senai.br/login.jsp" target="_blank">
                {t('buttons.login')}
              </LoginButton>
              {/*<LoginButton href="/login">Login</LoginButton>*/}
              <Dropdown
                overlay={
                  <LanguageList>
                    <LanguageContainer onClick={() => handleChangeLanguage('en-US')}>
                      <span>English</span>
                      <img
                        alt="United States"
                        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                      />
                    </LanguageContainer>
                    <LanguageContainer onClick={() => handleChangeLanguage('es-ES')}>
                      <span>Español</span>

                      <img
                        alt="United States"
                        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg"
                      />
                    </LanguageContainer>
                    <LanguageContainer onClick={() => handleChangeLanguage('pt-BR')}>
                      <span>Português</span>
                      <img
                        alt="United States"
                        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg"
                      />
                    </LanguageContainer>
                  </LanguageList>
                }
              >
                <TranslationButton>
                  <GlobalOutlined />

                  <img
                    alt="Language Flag"
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${languageFlag}.svg`}
                  />
                </TranslationButton>
              </Dropdown>
            </ButtonsContainer>
          </HeaderContent>
        )}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
