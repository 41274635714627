import { Route, Routes } from 'react-router-dom';
import './translation';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Home from './pages/Home';
import Login from './pages/Login';
import locales from './translation/locales';

function App() {
  const { i18n } = useTranslation();
  const localStorageLanguage = localStorage.getItem('@pcmd/language');
  useEffect(() => {
    if (localStorageLanguage) {
      i18n.changeLanguage(localStorageLanguage);
    }
  }, [i18n, localStorageLanguage]);

  useEffect(() => {
    const userLanguage = window.navigator.language;

    const translationsOptions = Object.keys(locales);
    const translation = translationsOptions.find((option) =>
      option.toLowerCase().includes(userLanguage.split('-')[0].toLowerCase())
    );

    if (localStorageLanguage) {
      i18n.changeLanguage(localStorageLanguage);
    } else if (translation) {
      i18n.changeLanguage(translation);
    } else {
      i18n.changeLanguage('pt-BR');
    }
  }, [i18n, localStorageLanguage]);

  return (
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="/login" Component={Login} />
    </Routes>
  );
}

export default App;
