import styled, { css } from 'styled-components';
import { ISyncAnimation } from '../../../interfaces/ISyncAnimation';

export const FirstScreenContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 62.5rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 62.5rem;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div<ISyncAnimation>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  height: 100%;

  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromLeft 1s ease-out;
    `}

  @media (max-width: 1023px) {
    width: 90%;
  }
`;

export const ContentLeftText1 = styled.div`
  margin: 1rem;
  word-break: break-word;
  color: #ffffff;
  font-family: 'Montserrat', Sans-serif;
  font-size: 31px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.4em;

  strong {
    font-family: 'Montserrat', Sans-serif;
    font-weight: 600;
  }
`;

export const ContentLeftText2 = styled.div`
  margin: 1rem;
  word-break: break-word;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  line-height: 1.7em;
`;

export const RegisterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-left: 1rem;
  padding: 1rem 2.5rem;
  background-color: #ffffff;
  border: 3px solid #ffffff;
  border-radius: 1.875rem;

  color: #000000;
  text-transform: uppercase;
  font-family: 'Roboto', Sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
`;

export const SenaiLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -6rem;

  @media (max-width: 1023px) {
    margin: 0;
    align-self: center;
    width: 70%;
  }

  img {
    width: 100%;
  }
`;

export const ContentRight = styled.div<ISyncAnimation>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;

  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromRight 1s ease-out;
    `}

  @media (max-width: 1023px) {
    width: 55%;
  }
  @media (max-width: 850px) {
    width: 60%;
  }
`;

export const ImageFader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  img {
    position: absolute;
    opacity: 0;
    animation-name: imagefade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 20s; // Duração total para as 4 imagens
    animation-fill-mode: forwards;
  }

  @keyframes imagefade {
    0%,
    25%,
    100% {
      opacity: 0;
    }
    5%,
    20% {
      opacity: 1;
    } // A imagem fica visível por 10% do tempo (2s)
  }

  img:nth-of-type(1) {
    animation-delay: 0s;
  }
  img:nth-of-type(2) {
    animation-delay: 5s;
  }
  img:nth-of-type(3) {
    animation-delay: 10s;
  }
  img:nth-of-type(4) {
    animation-delay: 15s;
  }

  @media (max-width: 1023px) {
    height: 40rem;
    img {
      width: 100%;
    }
  }

  @media (max-width: 850px) {
    height: 30rem;
  }
`;
