import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 8rem;

  background: #184e9a;
`;

export const FooterImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  width: 100%;
  height: 5rem;
  max-width: 40rem;

  @media (max-width: 1023px) {
    justify-content: flex-start;

    gap: 2rem;
  }
`;

export const FooterImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
`;

export const FooterText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  color: #fff;
  font-family: 'Open Sans', Sans-serif;
  font-size: 12px;
  font-weight: 300;
`;
