import { useTranslation } from 'react-i18next';
import SSMI1 from '../../../assets/images/SSMI1.png';
import useOnScreen from '../../../hooks/useOnScreen';
import Divider from '../Divider';
import Form from './Form';
import { ContentLeft, ContentRight, JoinScreenContainer, JoinScreenContent, JoinScreenTitle } from './styles';

const JoinScreen = () => {
  const [ref, isVisible] = useOnScreen({
    rootMargin: '0px',
  });
  const { t } = useTranslation();

  return (
    <JoinScreenContainer>
      <JoinScreenTitle>{t('screens.join.content1')}</JoinScreenTitle>
      <Divider />
      <JoinScreenContent>
        <ContentLeft ref={ref} isVisible={isVisible}>
          <img src={SSMI1} alt="SSMI image 1" />
        </ContentLeft>
        <ContentRight ref={ref} isVisible={isVisible}>
          <Form />
        </ContentRight>
      </JoinScreenContent>
    </JoinScreenContainer>
  );
};

export default JoinScreen;
