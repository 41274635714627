import styled from 'styled-components';

export const ThirdScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 90rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  gap: 2rem;
`;

export const ThirdScreenTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 71.25rem;
  gap: 1rem;

  text-align: center;
  color: #fff;
  font-family: 'Inter', Sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 1;

  span {
    font-family: 'Open Sans', Sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.7em;
  }
`;

export const ThirdScreenContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
