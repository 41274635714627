import { useTranslation } from 'react-i18next';
import PCMDLogo from '../../assets/images/logo4x.png';
import SENAILogo from '../../assets/images/senai-logo.png';
import { FooterContainer, FooterImage, FooterImagesContainer, FooterText } from './styles';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterImagesContainer>
        <FooterImage src={SENAILogo} alt="SENAI Logo" />
        <FooterImage src={PCMDLogo} alt="PCMD Logo" />
      </FooterImagesContainer>
      <FooterText>{t('footer.contact')}</FooterText>
      <FooterText>{t('footer.rights')}</FooterText>
    </FooterContainer>
  );
};

export default Footer;
