import { Step } from '../../../../../interfaces/IStep';
import { CardContainer, StepIcon, StepList, StepListItem, StepNumber, StepTitle } from './styles';

const Card = ({ step }: { step: Step }) => {
  return (
    <CardContainer>
      <StepNumber>{step?.number}</StepNumber>
      <StepIcon>{step?.icon}</StepIcon>
      <StepTitle>{step?.title}</StepTitle>
      <StepList>
        {step?.list.map((item: string) => {
          return <StepListItem key={item}>{item}</StepListItem>;
        })}
      </StepList>
    </CardContainer>
  );
};

export default Card;
