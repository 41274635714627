import styled, { css } from 'styled-components';
import { ISyncAnimation } from '../../../interfaces/ISyncAnimation';

export const JoinScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 90rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  gap: 2rem;
`;

export const JoinScreenTitle = styled.div`
  width: 100%;
  max-width: 71.25rem;

  text-align: center;
  color: #fff;
  font-family: 'Inter', Sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 1;
`;

export const JoinScreenContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  @media (max-width: 1023px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div<ISyncAnimation>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: -3.5rem;

  img {
    margin-left: -2rem;
    width: 115%;
  }

  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromLeft 1s ease-out;
    `}

  @media (max-width: 1023px) {
    margin-top: -8rem;

    img {
      width: 200%;
      margin-left: 0;
      align-self: center;
    }
  }

  @media (max-width: 850px) {
    margin-top: -7rem;
  }

  @media (max-width: 763px) {
    margin-top: -6rem;
  }

  @media (max-width: 600px) {
    margin-top: -5rem;
  }

  @media (max-width: 450px) {
    margin-top: -4.5rem;
  }
`;

export const ContentRight = styled.div<ISyncAnimation>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 29.625rem;
  height: 100%;

  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromRight 1s ease-out;
    `}

  @media (max-width: 1023px) {
    margin-top: -8rem;
  }

  @media (max-width: 850px) {
    margin-top: -6rem;
  }
`;
