import { useTranslation } from 'react-i18next';
import SSMI1 from '../../../assets/images/SSMI1.png';
import useOnScreen from '../../../hooks/useOnScreen';
import Divider from '../Divider';
import {
  ContentLeft,
  ContentRight,
  ContentRightText1,
  ContentRightText2,
  ContentRightText3,
  SecondScreenContainer,
  SecondScreenContent,
  SecondScreenTitle,
} from './styles';

const SecondScreen = () => {
  const [ref, isVisible] = useOnScreen({
    rootMargin: '0px',
  });
  const { t } = useTranslation();

  return (
    <SecondScreenContainer>
      <SecondScreenTitle>
        <strong>{t('screens.second.content1')}</strong> {t('screens.second.content2')}{' '}
        <strong>{t('screens.second.content3')}</strong>
      </SecondScreenTitle>
      <Divider />
      <SecondScreenContent>
        <ContentLeft ref={ref} isVisible={isVisible}>
          <img src={SSMI1} alt="SSMI image 1" />
        </ContentLeft>
        <ContentRight ref={ref} isVisible={isVisible}>
          <ContentRightText1>{t('screens.second.content4')}</ContentRightText1>
          <ContentRightText2>{t('screens.second.content5')}</ContentRightText2>
          <ContentRightText3>
            <li>{t('screens.second.content6')}</li>
            <li>{t('screens.second.content7')}</li>
            <li>{t('screens.second.content8')}</li>
            <li>{t('screens.second.content9')}</li>
            <li>{t('screens.second.content10')}</li>
          </ContentRightText3>
        </ContentRight>
      </SecondScreenContent>
    </SecondScreenContainer>
  );
};

export default SecondScreen;
