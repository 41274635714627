export default {
  translation: {
    buttons: {
      login: 'Login',
      join: 'Quiero registrarme',
      software: 'Software',
      how: 'Cómo funciona',
      about: 'Sobre el proyecto',
      video: 'Vídeo',
      video_sec: '¡Ver el vídeo completo!',
      send: 'Enviar',
    },
    placeholders: {
      name: 'Nombre Completo',
      position: 'Cargo',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      company: 'Empresa',
      registry: 'CNPJ',
    },
    screens: {
      first: {
        content1: 'GESTIÓN DE ACTIVOS Y MANTENIMIENTO CON',
        content2: 'EL APOYO DE QUIENES ENTENDEN!',
        content3: 'Evita fallos en tus máquinas con un software SENCILLO, INTUITIVO Y PARTICIPATIVO',
        content4:
          'Nuestro equipo de expertos de SENAI seguirá todo el proceso de transformación. ¡Solicita una evaluación gratuita!',
        content5: 'Hay pocas plazas.',
        content6: 'Regístrate',
        content7: 'para confirmar el interés en participar en el programa!',
        content8: '* Evaluaremos el perfil de la empresa y la adherencia a las reglas de ABDI.',
        content9: 'Realización',
      },
      sponsors: {
        title: 'APOYO',
      },
      video: {
        content1: 'Conoce el PCM Digital',
        content2:
          'El PCM Digital apoya a las empresas en la implementación de un software completo e intuitivo para mejorar el',
        content3: 'planificación y control de las acciones de mantenimiento',
        content4: 'con',
        content5: 'seguimiento inmersivo',
        content6: 'del equipo de',
        content7: 'SENAI',
        content8: '¡Ver el vídeo completo!',
        content9: 'PCM DIGITAL - PRESENTACIÓN',
      },
      second: {
        content1: 'Detén',
        content2: 'el desperdicio de tiempo y dinero en paradas de',
        content3: 'mantenimiento inesperadas!',
        content4:
          'Si tu empresa lucha por controlar el mantenimiento de máquinas, quiere mejorar el rendimiento de producción o ahorrar en suministros y materiales, el PCM digital es la solución ideal.',
        content5: 'EL PCM DIGITAL ES PARA EMPRESAS QUE QUIEREN:',
        content6: 'Evitar fallos en máquinas y paradas de producción',
        content7: 'Optimizar los costos de mantenimiento',
        content8: 'Reducir los inventarios de piezas y materiales de mantenimiento',
        content9: 'Hacer un mejor uso de la mano de obra y contratos de servicios',
        content10: 'Tomar mejores decisiones con datos e indicadores',
      },
      third: {
        content1: 'Cómo funciona',
        content2: 'Con el seguimiento inmersivo de SENAI, el proyecto tendrá las siguientes etapas:',
        cards: {
          diagnostics: {
            title: 'Diagnósticos',
            content1: 'Mapeo de activos',
            content2: 'Entendimiento de los flujos de información',
            content3: 'Evaluación de la madurez de la empresa',
            content4: 'Provisión de software de SENAI',
            content5: 'Capacitación de usuarios',
          },
          registers: {
            title: 'Registros',
            content1: 'Registro de activos',
            content2: 'Emisión de etiquetas con códigos QR',
            content3: 'Registro de empleados, proveedores y contratos',
          },
          services: {
            title: 'Servicios',
            content1: 'Registros de mantenimiento',
            content2: 'Solicitud de servicio (SS)',
            content3: 'Órdenes de trabajo (OT)',
          },
          planning: {
            title: 'Planificación',
            content1: 'Desarrollo de planes de mantenimiento',
            content2: 'Procedimientos estandarizados',
            content3: 'Planificación y control de acciones',
            content4: 'Inspecciones de lista de verificación',
          },
          indicators: {
            title: 'Indicadores',
            content1: 'Información para la toma de decisiones',
            content2: 'Gráficos e informes fáciles de entender',
            content3: 'Gestión visual',
            content4: 'Control de costos de mantenimiento',
            content5: 'Estadísticas de Órdenes de Trabajo',
          },
        },
      },
      software: {
        content1: 'Software',
        content2: 'Software completo, simple e intuitivo',
        content3: 'Desarrollado por SENAI',
        content4: 'Interfaz amigable',
        content5: 'Sin instalación (100% nube)',
        content6: 'Sin límite de usuarios',
        content7: 'Actualizaciones automáticas',
        content8: 'Ideal para smartphones y tabletas',
        content9: 'Mejores prácticas en seguridad de datos',
        content10: 'Soporte especializado de SENAI',
        card1: {
          title: 'Almacén de materiales',
          content1: 'Gestión de materiales y piezas',
          content2: 'Alertas de stock mínimo',
          content3: 'Solicitud y aprobación de compras',
          content4: 'Control de entradas y salidas',
        },
        card2: {
          title: 'Indicadores de Rendimiento',
          content1: 'Costo e impactos de producción',
          content2: 'Tiempo entre Fallas y Tiempo de Reparación',
          content3: 'Disponibilidad y Confiabilidad',
          content4: 'Rendimiento (mano de obra y conductores)',
          content5: 'Costo total operacional de flotas',
        },
        card3: {
          title: 'Movilidad',
          content1: 'Registro simplificado de activos por móvil',
          content2: 'Lectura de etiquetas (TAG) con QR-Code',
          content3: 'Acceso a información y adjuntos desde cualquier lugar',
        },
        card4: {
          title: 'Gestión de servicios',
          content1: 'Solicitudes de Servicios (SS)',
          content2: 'Ordenes de Servicios (OS)',
          content3: 'Gestión visual',
        },
        card5: {
          title: 'Panel de Gestión',
          content1: 'Agenda de servicios',
          content2: 'Estadísticas de órdenes de servicio',
          content3: 'Estado de activos',
          content4: 'Principales indicadores de mantenimiento',
        },
        card6: {
          title: 'Registro de Activos',
          content1: 'Fotos y adjuntos',
          content2: 'Edificaciones y sectores',
          content3: 'Líneas de producción',
          content4: 'Máquinas y equipos',
          content5: 'Información auditable',
        },
        card7: {
          title: 'Planificación de Mantenimiento',
          content1: 'Planes preventivos automatizados',
          content2: 'Checklist e inspecciones predictivas',
          content3: 'Rutas de Lubricación',
        },
        card8: {
          title: 'Gestión de Flotas',
          content1: 'Gestión de materiales y piezas',
          content2: 'Alertas de stock mínimo',
          content3: 'Solicitud y aprobación de compras',
          content4: 'Control de entradas y salidas',
        },
      },
      limited: {
        title: 'Plazas Limitadas',
        card1: {
          title: 'Servicios',
          content1: 'Registro de activos de clientes',
          content2: 'Historial de mantenimientos realizados a clientes',
          content3: 'Control del material utilizado por cada cliente',
          content4: 'Control de la periodicidad de los servicios en clientes',
          content5: 'Gestión de contratos de servicio',
          content6: 'Posibilidad de acciones preventivas en el cliente',
          content7: 'Programación de vehículos',
          content8: 'Indicadores de rendimiento',
        },
        card2: {
          title: 'Agronegocios',
          content1: 'Registro de maquinaria e implementos agrícolas',
          content2: 'Registro de edificaciones',
          content3: 'Control de planes de mantenimiento',
          content4: 'Gestión de abastecimientos y neumáticos',
          content5: 'Gestión y evaluación de contratos de terceros',
          content6: 'Control de materiales con mínimos y máximos',
          content7: 'Movilidad',
          content8: 'Indicadores de rendimiento',
        },
        card3: {
          title: 'Industria',
          content1: 'Registro de líneas, máquinas, equipos y la criticidad de cada uno',
          content2: 'Control de planes de mantenimiento',
          content3: 'Optimización de servicios y mano de obra',
          content4: 'Gestión y evaluación de contratos de terceros',
          content5: 'Control de materiales con inventarios mínimos y máximos',
          content6: 'Gestión de la seguridad de las máquinas y equipos (NR12)',
          content7: 'Control de flotas Indicadores de rendimiento',
        },
        alt1: 'Servicios',
        alt2: 'Agronegocios',
        alt3: 'Industria',
      },
      join: {
        content1: 'Quiero participar',
      },
    },
    footer: {
      contact: 'Rua Pimenta Bueno, 370 – Amambai, Campo Grande – MS • Teléfono: (67) 3311-8511 • Campo Grande / MS',
      rights: 'Todos los derechos reservados ® 2022.',
    },
  },
};
