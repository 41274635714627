import FirstScreen from './FirstScreen';
import JoinScreen from './JoinScreen';
import LimitedScreen from './LimitedScreen';
import SecondScreen from './SecondScreen';
import SoftwareScreen from './SoftwareScreen';
import Sponsors from './Sponsors';
import ThirdScreen from './ThirdScreen';
import VideoScreen from './VideoScreen';
import {
  ContentContainer,
  FirstScreenWrapper,
  SecondaryScreenWrapper,
  SectionDivider,
  VideoScreenWrapper,
} from './styles';

const Content = () => {
  return (
    <ContentContainer>
      <FirstScreenWrapper>
        <FirstScreen />
      </FirstScreenWrapper>
      <SecondaryScreenWrapper>
        <Sponsors />
      </SecondaryScreenWrapper>
      <div style={{ position: 'relative' }}>
        <div id="video-screen" style={{ position: 'absolute', top: '-100px' }} />
      </div>
      <VideoScreenWrapper>
        <VideoScreen />
      </VideoScreenWrapper>
      <div style={{ position: 'relative' }}>
        <div id="about-screen" style={{ position: 'absolute', top: '-50px' }} />
      </div>
      <SecondaryScreenWrapper>
        <SecondScreen />
      </SecondaryScreenWrapper>
      <div style={{ position: 'relative' }}>
        <div id="how-works-screen" style={{ position: 'absolute', top: '-50px' }} />
      </div>
      <SecondaryScreenWrapper>
        <ThirdScreen />
      </SecondaryScreenWrapper>
      <div style={{ position: 'relative' }}>
        <div id="software-screen" style={{ position: 'absolute', top: '-30px' }} />
      </div>
      <SecondaryScreenWrapper>
        <SoftwareScreen />
      </SecondaryScreenWrapper>
      <SecondaryScreenWrapper>
        <LimitedScreen />
      </SecondaryScreenWrapper>
      <div style={{ position: 'relative' }}>
        <div id="join-screen" style={{ position: 'absolute', top: '-30px' }} />
      </div>
      <SecondaryScreenWrapper>
        <JoinScreen />
      </SecondaryScreenWrapper>
      <SecondaryScreenWrapper>
        <SectionDivider />
      </SecondaryScreenWrapper>
    </ContentContainer>
  );
};

export default Content;
