import styled from 'styled-components';

export const SponsorsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 66.5rem;

  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.7em;

  @media (max-width: 1023px) {
    align-items: center;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 62.5rem;
  margin-top: 2rem;
  gap: 5rem;

  img {
    height: 5rem;
  }
`;
