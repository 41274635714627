import styled, { css } from 'styled-components';
import { ISyncAnimation } from '../../../interfaces/ISyncAnimation';

export const VideoScreenContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 66.5rem;
  margin: 3rem 0;
  gap: 5rem;

  @media (max-width: 1023px) {
    flex-direction: column;
    max-width: 30rem;
  }
`;

export const VideoDescriptionContainer = styled.div<ISyncAnimation>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;

  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromLeft 1s ease-out;
    `}

  @media (max-width: 1023px) {
    padding: 0 1rem;
  }
`;

export const VideoDescriptionTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: #1b2d43;
  font-family: 'Open Sans', Sans-serif;
  font-size: 32px;
  font-weight: 700;
`;

export const VideoDescriptionText = styled.span`
  margin-top: 1rem;
  color: #1b2d43;
  font-family: 'Open Sans', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  word-break: break-word;
`;

export const VideoWrapper = styled.div<ISyncAnimation>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      animation: slideFromRight 1s ease-out;
    `}
`;

export const VideoButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  max-width: 21rem;
  height: 1.75rem;
  margin-top: 3rem;
  padding: 0.75em;
  border: none;
  border-radius: 4px;
  background-color: #0291da;
  text-decoration: none;

  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1em;

  cursor: pointer;
  :hover {
    background-color: #e6e6e6;
  }
`;
