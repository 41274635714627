export default {
  translation: {
    buttons: {
      login: 'Login',
      join: 'I want to register',
      software: 'Software',
      how: 'How it works',
      about: 'About the project',
      video: 'Video',
      video_sec: 'Watch the full video!',
      send: 'Send',
    },
    placeholders: {
      name: 'Full Name',
      position: 'Position',
      email: 'E-mail',
      phone: 'Phone',
      company: 'Company',
      registry: 'CNPJ',
    },
    screens: {
      first: {
        content1: 'ASSET MANAGEMENT AND MAINTENANCE WITH',
        content2: 'THE SUPPORT OF THOSE WHO UNDERSTAND!',
        content3: 'Avoid failures in your machines with SIMPLE, INTUITIVE AND PARTICIPATORY software',
        content4: 'Our team of SENAI experts will follow the entire transformation process. Request a free evaluation!',
        content5: 'There are few spots.',
        content6: 'Register',
        content7: 'to confirm interest in participating in the program!',
        content8: "* We will evaluate the company's profile and adherence to ABDI rules.",
        content9: 'Realization',
      },
      sponsors: {
        title: 'SUPPORT',
      },
      video: {
        content1: 'Meet Digital PCM',
        content2: 'Digital PCM supports companies in implementing a complete and intuitive software to improve the',
        content3: 'planning and control of maintenance actions',
        content4: 'with',
        content5: 'immersive monitoring',
        content6: 'from the team at',
        content7: 'SENAI',
        content8: 'Watch the full video!',
        content9: 'DIGITAL PCM - PRESENTATION',
      },
      second: {
        content1: 'Stop',
        content2: 'wasting time and money on unexpected',
        content3: 'maintenance stops!',
        content4:
          'If your company struggles to control machine maintenance, wants to improve production performance or save on supplies and materials, digital PCM is the ideal solution.',
        content5: 'DIGITAL PCM IS FOR COMPANIES THAT WANT:',
        content6: 'To avoid failures in machines and production stops',
        content7: 'To optimize maintenance costs',
        content8: 'To reduce maintenance parts and materials inventories',
        content9: 'To make better use of labor and service contracts',
        content10: 'To make better decisions with data and indicators',
      },
      third: {
        content1: 'How it works',
        content2: 'With immersive monitoring by SENAI, the project will have the following stages:',
        cards: {
          diagnostics: {
            title: 'Diagnostics',
            content1: 'Asset mapping',
            content2: 'Understanding of information flows',
            content3: 'Company maturity assessment',
            content4: 'SENAI software provision',
            content5: 'User training',
          },
          registers: {
            title: 'Registers',
            content1: 'Asset registration',
            content2: 'Issuance of Tags with QR codes',
            content3: 'Registration of employees, suppliers, and contracts',
          },
          services: {
            title: 'Services',
            content1: 'Maintenance records',
            content2: 'Service request (SR)',
            content3: 'Work orders (WO)',
          },
          planning: {
            title: 'Planning',
            content1: 'Development of maintenance plans',
            content2: 'Standardized procedures',
            content3: 'Planning and control of actions',
            content4: 'Checklist inspections',
          },
          indicators: {
            title: 'Indicators',
            content1: 'Information for decision making',
            content2: 'Charts and reports easy to understand',
            content3: 'Visual management',
            content4: 'Maintenance cost control',
            content5: 'Work Order statistics',
          },
        },
      },
      software: {
        content1: 'Software',
        content2: 'Complete, simple, and intuitive software',
        content3: 'Developed by SENAI',
        content4: 'User-friendly interface',
        content5: 'No installation (100% cloud)',
        content6: 'Unlimited users',
        content7: 'Automatic updates',
        content8: 'Ideal for smartphones and tablets',
        content9: 'Best practices in data security',
        content10: 'Specialized SENAI support',
        card1: {
          title: 'Material Warehouse',
          content1: 'Management of materials and parts',
          content2: 'Minimum stock alerts',
          content3: 'Purchase requests and approvals',
          content4: 'Control of entries and exits',
        },
        card2: {
          title: 'Performance Indicators',
          content1: 'Cost and impacts of production',
          content2: 'Time Between Failures and Repair Time',
          content3: 'Availability and Reliability',
          content4: 'Yield (labor and conductors)',
          content5: 'Total operational cost of fleets',
        },
        card3: {
          title: 'Mobility',
          content1: 'Simplified asset registration via mobile',
          content2: 'Reading tags (TAG) with QR-Code',
          content3: 'Access to information and attachments from anywhere',
        },
        card4: {
          title: 'Service Management',
          content1: 'Service Requests (SR)',
          content2: 'Work Orders (WO)',
          content3: 'Visual management',
        },
        card5: {
          title: 'Management Dashboard',
          content1: 'Service schedule',
          content2: 'Statistics of work orders',
          content3: 'Asset status',
          content4: 'Main maintenance indicators',
        },
        card6: {
          title: 'Asset Registration',
          content1: 'Photos and attachments',
          content2: 'Buildings and sectors',
          content3: 'Production lines',
          content4: 'Machines and equipment',
          content5: 'Auditable information',
        },
        card7: {
          title: 'Maintenance Planning',
          content1: 'Automated preventive plans',
          content2: 'Checklists and predictive inspections',
          content3: 'Lubrication routes',
        },
        card8: {
          title: 'Fleet Management',
          content1: 'Management of materials and parts',
          content2: 'Minimum stock alerts',
          content3: 'Purchase requests and approvals',
          content4: 'Control of entries and exits',
        },
      },
      limited: {
        title: 'Limited Spots',
        card1: {
          title: 'Services',
          content1: "Registration of client's assets",
          content2: 'History of maintenance performed on clients',
          content3: 'Control of materials each client uses',
          content4: 'Control of service periodicity on clients',
          content5: 'Management of service provision contracts',
          content6: 'Possibility of preventive actions at the client',
          content7: 'Scheduling of vehicles',
          content8: 'Performance indicators',
        },
        card2: {
          title: 'Agribusiness',
          content1: 'Registration of agricultural machinery and implements',
          content2: 'Building registration',
          content3: 'Maintenance plan control',
          content4: 'Fuel and tires management',
          content5: 'Management and assessment of third-party contracts',
          content6: 'Materials control with minimum and maximum levels',
          content7: 'Mobility',
          content8: 'Performance indicators',
        },
        card3: {
          title: 'Industry',
          content1: 'Registration of lines, machines, equipment and the criticality of each',
          content2: 'Maintenance plan control',
          content3: 'Optimization of services and labor',
          content4: 'Management and assessment of third-party contracts',
          content5: 'Materials control with minimum and maximum stock levels',
          content6: 'Safety management of machines and equipment (NR12)',
          content7: 'Fleet control Performance indicators',
        },
        alt1: 'Services',
        alt2: 'Agribusiness',
        alt3: 'Industry',
      },
      join: {
        content1: 'I want to participate',
      },
    },
    footer: {
      contact: 'Rua Pimenta Bueno, 370 – Amambai, Campo Grande – MS • Phone: (67) 3311-8511 • Campo Grande / MS',
      rights: 'All rights reserved ® 2022.',
    },
  },
};
